




















import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Component, { mixins } from 'vue-class-component';
import ResourcesFormComponent from '@/components/ResourcesFormComponent.vue';
import Session from '@/models/graphql/Session';
import { Prop } from 'vue-property-decorator';
import { Data } from '@/utils/types/WidgetData';
import FileType from '@/utils/enums/FileType';
import { namespace } from 'vuex-class';
import FileResource from '@/models/graphql/FileResource';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

const fileResourceStore = namespace('FileResourceStore');

@Component({
  components: {
    ResourcesFormComponent,
  },
})
export default class SessionCmsCoverWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  baseStoreName = 'SessionCmsCoverWidgetStore';

  @Prop({ required: false, default: () => [] })
  private readonly data!: Data[];

  @Prop({ required: false, default: null })
  private readonly entityCode!: string;

  @fileResourceStore.Action
  private deleteFileResource!: (uid: string) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private createBannerFileResourceForSession!: (data: {
    uid: string;
    uploadToken: string;
    fileName: string;
    fileType: string;
  }) => Promise<FileResource | undefined>;

  private fileType = `${FileType.PNG},${FileType.JPG},${FileType.JPEG},${FileType.WEBP}`;

  created(): void {
    this.setDataConfig();
  }

  private get session(): Session | null {
    if (this.data.length > 0) {
      return Session.hydrate(this.data[0]);
    }
    return null;
  }
}
